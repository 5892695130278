import 'moment/locale/zh-cn';
import 'antd/dist/antd.css';
import zhCN from 'antd/es/locale/zh_CN';
import React from 'react';
import ReactDOM from 'react-dom';
import moment from 'moment';
import {ConfigProvider} from 'antd';
import { Provider } from 'react-redux';
import App from '@/App';
import store from "@/store";


moment.locale('zh-cn');
ReactDOM.render(
    <Provider store={store}>
        <ConfigProvider locale={zhCN}>
            <App />
        </ConfigProvider>
    </Provider>
    ,
    document.querySelector("#root")
)