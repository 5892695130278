import {applyMiddleware, createStore} from 'redux';
import thunk from 'redux-thunk';

const initState = {

}

const reducer = function(state = initState, action){

}

export default createStore(reducer, applyMiddleware(thunk));