import md5 from 'blueimp-md5';
import qs from "qs";
import moment from "moment";
import { v4 as uuid } from 'uuid';
export const encrypt = (target, salt = '@2024,Year#Good|Luck!') => {
  return md5(target + salt);
}

export const stringify = (url, params) => {
  return !!params?`${url}?${qs.stringify(params)}`: url;
}

export const delayLocation = (fn, duration = 300) => {
  setTimeout(fn, duration);
}

// 内存管理
const USER_TOKEN = 'user_token';

const setItem = (key, item) => {
  localStorage.setItem(key, JSON.stringify(item));
}

const getItem = (key) => {
  let token = localStorage.getItem(key);
  return JSON.parse(token);
}

const removeItem = (key) => {
  localStorage.removeItem(key)
}
export const getToken = () => getItem(USER_TOKEN)

export const setToken = (item) => setItem(USER_TOKEN, item)

export const removeToken = () => removeItem(USER_TOKEN);

export const listInitState = {
  current: 1,
  pageSize: 10,
  total: 0,
  dataSource: [],
  loading: true
}


export const toMoment = (date) => {
  return date ? moment(date) : null;
}

const KEY_PREFIX = 'key:'
export const uuidKey = () => {
  return KEY_PREFIX + uuid();
}

export const removeKeys = (items) => {
  return items.map((e) => {
    const {id, ...rest} = e;
    if(id.indexOf(KEY_PREFIX) >= 0){
      return rest;
    }else{
      return e;
    }
  })
}