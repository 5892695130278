import routes from '@/routes';
import {Switch, Route, Redirect} from "react-router-dom";
import React from "react";
import {getToken} from "@/utils";


const AuthRoute = ({children}) => {
  return (
    <Route
      render={() => {
        let token = getToken();
        if(!token){
          return <Redirect to="/login" />
        }
        return children;
      }}
    />
  );
}

const createRoute = (r) => {
  const {children, key, component: Parent, ...rest} = r;
  if(children){
    return (
      <AuthRoute key={key}>
        <Parent {...rest}>
          <Switch>{children.map(e => createRoute(e))}</Switch>
        </Parent>
      </AuthRoute>
    );
  }
  return <Route {...r} key={key}></Route>
}

const AppRoutes = () => {
  return (
    <Switch>
      {routes.map((e) => createRoute(e))}
    </Switch>
  );
}
export default AppRoutes;