import {Spin} from 'antd';


const Spinning = () => {
  return (
    <Spin>
      <div className="spin"></div>
    </Spin>
  );
}

export default Spinning;