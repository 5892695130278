import React, {Component, Suspense} from 'react';
import Spinning from '@components/Spin';
import {BrowserRouter as Router} from 'react-router-dom';
import AppRoutes from "@components/AppRoutes";

class App extends Component{
    render(){
        return (
          <Suspense fallback={<Spinning />}>
            <Router>
                <AppRoutes />
            </Router>
          </Suspense>
        );
    }
}

export default App;