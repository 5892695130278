import { lazy } from "react";

const routes = [
  {
    exact: true,
    key: 'login',
    path: '/login',
    component: lazy(() => import('@views/Login'))
  },
  {
    key: 'register',
    path: '/register',
    component: lazy(() => import('@views/MemberRegister'))
  },
  {
    key: 'download',
    path: '/download',
    component: lazy(() => import('@views/Download'))
  },
  {
    key: 'requirement',
    path: '/requirement',
    component: lazy(() => import('@views/Requirement'))
  },
  {
    key: 'index',
    path: '/',
    component: lazy(() => import('@views/Admin')),
    children: [
      {
        exact: true,
        key: 'home',
        path: '/',
        component: lazy(() => import('@views/Admin/Home'))
      },
      {
        key: 'menu',
        path: '/menu',
        component: lazy(() => import('@views/Admin/Menu'))
      },
      {
        key: 'article',
        path: '/article',
        component: lazy(() => import('@views/Admin/Article'))
      },
      {
        key: 'user',
        path: '/user',
        component: lazy(() => import('@views/Admin/User'))
      },
      {
        key: 'course',
        path: '/course',
        component: lazy(() => import('@views/Admin/Course'))
      },
      {
        key: 'carousel',
        path: '/carousel',
        component: lazy(() => import('@views/Admin/Carousel'))
      },
      {
        key: 'member',
        path: '/member',
        component: lazy(() => import('@views/Admin/Member'))
      },
      {
        key: 'file',
        path: '/file',
        component: lazy(() => import('@views/Admin/Download'))
      },
      {
        key: 'supply',
        path: '/supply',
        component: lazy(() => import('@views/Admin/Supply'))
      },
      {
        key: 'expert',
        path: '/expert',
        component: lazy(() => import('@views/Admin/Expert'))
      }
    ]
  }
]
export default routes;
